<template>
  <b-overlay :show="showOverlay">
    <validation-observer ref="createBlogContent" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Game service content</h2>
        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Full Description"
              >

                <ckEditorMain ref="editorContent" :defaultContent="data.description"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>

              </b-form-group>

            </b-col>
            <b-col cols="3">
              <b-button
                  class="mt-2 ml-2"
                  variant="primary"
                  @click="createBlogContent"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCarousel,
  BCarouselSlide,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { CreateBlogContent } from '@/libs/Api/Blog'

export default {
  components: {
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  name: 'create-blog-content',
  title: 'create blog content',
  data() {
    return {
      //service 1
      // gold 2
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      data: {
        title: '',
        description: '',
      },
    }
  },

  methods: {

    setEditorContent(content) {
      this.data.description = content
    },
    async createBlogContent() {
      let _this = this
      Helper.validateForm(_this, 'createBlogContent', async () => {
        _this.showOverlay = true
        let createBlogContent = new CreateBlogContent(_this)
        createBlogContent.setParams({
          BlogId: _this.$route.query.blogId
        })
        createBlogContent.setPayload(_this.data)
        await createBlogContent.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/pages/blog/edit/${_this.$route.query.blogId}`)
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>
